<template>
  <div
    v-if="!isLegacyBrowser"
    class="theme-toggle__wrapper"
    :aria-labelledby="`theme-toggle-wrapper-${uniqueID}`"
  >
    <div class="theme-toggle__label">Hell</div>
    <div class="theme-toggle__switch">
      <input
        id="theme-toggle-checkbox"
        class="theme-toggle__checkbox"
        :data-cy="dataCy"
        type="checkbox"
        :tabindex="tabindex"
        :checked="modelValue"
        :aria-checked="modelValue ? 'true' : 'false'"
        :aria-labelledby="`theme-toggle-toggle-${uniqueID}`"
        @keydown.space.enter.prevent.self="toggleTheme"
        @change="toggleTheme"
      />
      <label class="theme-toggle__switch--label" for="theme-toggle-checkbox">
        <div class="theme-toggle__switch--ball" />
      </label>
    </div>
    <div class="theme-toggle__label theme-toggle__switch--label-dark">
      Dunkel
    </div>
    <span
      :id="`theme-toggle-wrapper-${uniqueID}`"
      aria-hidden="true"
      class="visually-hidden"
    >
      Farbschema der Seite wählen
    </span>
    <span
      :id="`theme-toggle-toggle-${uniqueID}`"
      aria-hidden="true"
      class="visually-hidden"
    >
      Wählen Sie das Farbschema der Seite. Sie haben die Wahl zwischen dem
      Standard hellen Modus und einem kontrastreichen dunklen Modus.
    </span>
  </div>
</template>

<script>
import { isLegacyBrowser, uniqueId } from 'src/lib/helpers'

export default {
  name: 'ToggleSwitch',
  props: {
    dataCy: { type: String, default: undefined },
    tabindex: { type: Number, default: undefined },
    modelValue: { type: Boolean, default: undefined }
  },
  emits: ['change'],
  data() {
    return {
      uniqueID: uniqueId()
    }
  },
  computed: {
    isLegacyBrowser
  },
  methods: {
    toggleTheme() {
      if (this.$attrs && this.$attrs.onClick) {
        this.$attrs.onClick()
      }

      this.$emit('change', !this.modelValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.theme-toggle__wrapper {
  display: flex;
}

.theme-toggle__switch {
  margin: 0 7px;
  position: relative;
}

.theme-toggle__checkbox {
  width: 36px;
  height: 20px;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);

  &:focus {
    border-radius: 14px;

    // Neccessary to get rounded corners on checkboxes
    appearance: none;
  }
}

.theme-toggle__switch--label {
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 36px;
  height: 20px;
  border: none;
  outline: none;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 14px;
  display: inline-block;
  background: var(--root-color);
}

.theme-toggle__switch--label-dark {
  margin-left: 36px;
}

.theme-toggle__switch--ball {
  background-color: var(--background-primary);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 16px;
  width: 16px;
  transition: all 300ms ease-in-out;
}

.theme-toggle__checkbox:checked
  + .theme-toggle__switch--label
  .theme-toggle__switch--ball {
  transform: translateX(16px);
}
</style>
